<template>
  <PageSection
    class="MediaNotifications"
    :page-tooltip="$t('pages.settings.personal.media_notifications.tooltip')"
    :page-title="$t('pages.settings.personal.media_notifications.headline')"
  >
    <div class="border-b-black has-text-right p-b-m m-b-m">
      <VButton
        class="is-black"
        data-testid="MediaNotifications__CreateTrigger"
        @click="isDialogVisible = true"
      >
        {{ $t('pages.settings.personal.media_notifications.create_notification') }}
      </VButton>
    </div>

    <LoadMore
      ref="loadMore"
      :endpoint="endpoint"
    >
      <template slot="item" slot-scope="{ item, removeItem }">
        <MediaNotificationsPanel
          :notification="item"
          class="m-b-m"
          @edit="handleEdit"
          @deleted="removeItem"
        />
      </template>
    </LoadMore>

    <MediaNotificationManagementModal
      :visible.sync="isDialogVisible"
      :notification="selectedNotification"
      @update="handleUpdate"
      @close="selectedNotification = null"
    />
  </PageSection>
</template>

<script>
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'

import PageSection from '@/components/core/PageSection.vue'
import LoadMore from '@/components/pagination/LoadMore.vue'
import MediaNotificationsPanel from '@/pages/settings/components/MediaNotificationsPanel.vue'
import MediaNotificationManagementModal from '@/pages/settings/components/MediaNotificationsManagementModal.vue'
import { MEDIA_NOTIFICATIONS_ENDPOINT } from '@/services/api/MediaNotificationsApiService.js'

export default {
  components: {
    VButton,
    PageSection,
    LoadMore,
    MediaNotificationManagementModal,
    MediaNotificationsPanel
  },

  data () {
    return {
      isDialogVisible: false,
      selectedNotification: null
    }
  },

  computed: {
    endpoint () {
      return MEDIA_NOTIFICATIONS_ENDPOINT
    }
  },

  methods: {
    handleEdit (notification) {
      this.selectedNotification = notification

      this.isDialogVisible = true
    },

    handleUpdate () {
      this.isDialogVisible = false

      this.selectedNotification = null

      return this.$refs.loadMore.refresh()
    }
  }
}
</script>
